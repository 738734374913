import { camelCase, isEmpty, orderBy, round, get } from 'lodash-es';
import { intervalToDuration } from 'date-fns';

import Civility from '@/entities/Civility';
import LeadVehicleCondition from '@/entities/LeadVehicleCondition';
import LeadVehicleFuel from '@/entities/LeadVehicleFuel';
import i18n from '../plugins/vue-i18n.js';
import ActivixDate from '../value-objects/ActivixDate.js';

import Service from '../entities/Service.js';
import LeadType from '../entities/LeadType.js';
import LeadVehicle from '../entities/LeadVehicle.js';

import { formatCurrency } from '../utils/numbers.js';
import { formatPhone, getFullName, sort, toMoney } from '../utils/index.js';
import { timeHms } from '../utils/time.js';
import { latinize } from '../utils/string.js';
import TaskEventType from '../entities/TaskEventType.js';
import Division from '../entities/Division.js';
import Lead from '../entities/Lead.js';
import CommunicationType from '../entities/CommunicationType.js';
import CommunicationMethod from '../entities/CommunicationMethod.js';
import { getIconMarkup } from '../utils/icon.js';
import ProgressState from '../entities/ProgressState.js';
import VehicleVinMatchType from '../entities/VehicleVinMatchType.js';
import ClientCardSection from '../entities/ClientCardSection.js';

const getVehicles = (lead, type = 'wanted') => {
    let vehicles = [];

    if (lead instanceof Lead) {
        if (type == 'wanted') {
            vehicles = lead.wanted_vehicles;
        } else {
            vehicles = lead.exchange_vehicles;
        }
    } else {
        vehicles = lead[`${type}_vehicles`] || [];
    }

    return orderBy(vehicles, 'sold', 'desc');
};

const dateFilterPositiveFormater = (filterOption, date, startDate, endDate) => {
    if (!(date instanceof ActivixDate)) {
        throw new Error('The parameter "date" must be an ActivixDate instance.');
    }

    if (filterOption == 'inside_dates' && date.isBetween(startDate, endDate)) {
        return 'inside_dates';
    }

    if (filterOption == 'outside_dates' && !date.isBetween(startDate, endDate)) {
        return 'outside_dates';
    }

    return 'yes';
};

export const extractFromHtml = (value, param = 'data-export') => {
    let start = value.indexOf(param);

    if (start === -1) {
        return value;
    }

    start += param.length + 2;

    value = value.substr(start);
    value = value.substr(0, value.indexOf('"'));

    return value;
};

export const searchFor = (value, searchString, exact = false, integerType = null) => {
    value = latinize(String(value).trim().toLowerCase());
    searchString = latinize(String(searchString).trim().toLowerCase());

    if (!searchString) {
        return false;
    }

    if (integerType) {
        value = parseInt(value, 10);
        searchString = parseInt(searchString, 10);

        if (integerType == 'min') {
            if (value >= searchString) {
                return true;
            }
        } else if (value <= searchString) {
            return true;
        }
    } else if (value.toLowerCase().indexOf(searchString) !== -1) {
        if (exact) {
            if (value.toLowerCase() == searchString.toLowerCase()) {
                return true;
            }
            return false;
        }
        return true;
    }

    // Return false if not found
    return false;
};

export const searchForMultiple = (value, searchStrings, exact = false, integerType = null) => {
    if (!Array.isArray(searchStrings)) {
        searchStrings = [searchStrings];
    }

    const found = searchStrings.some(searchString => searchFor(value, searchString, exact, integerType));

    return found || (searchStrings.includes('none') && (!value || ['none', 'aucun'].includes(value.toLowerCase())));
};

export const searchArray = (value, array) => {
    for (let searchIndex = 0; searchIndex < array.length; searchIndex++) {
        if (value.indexOf(parseInt(array[searchIndex], 10)) !== -1) {
            return true;
        }

        if (array.includes('none') && isEmpty(value)) {
            return true;
        }
    }

    return false;
};

export const formater = {
    formatClientName(lead) {
        let fullName = lead.fullName || i18n.t('general.unknown');

        if (lead.second_contact) {
            fullName += '\n';
            fullName += lead.second_contact;
        }

        return fullName;
    },

    formatPhone(lead, html = false) {
        let returnValue = '';

        lead.lead_phones.forEach(phone => {
            const number = phone.number || phone.phone;
            const formattedNumber = formatPhone(number);

            if (phone.valid && formattedNumber) {
                if (returnValue) {
                    returnValue += html ? '<br>' : '\n';
                }

                returnValue += formattedNumber;
            }
        });

        return returnValue;
    },

    formatEmail(lead, html = false) {
        let returnValue = '';

        lead.lead_emails.forEach(email => {
            const address = email.address || lead.email;

            if (email.valid && address) {
                if (returnValue != '') {
                    returnValue += html ? '<br>' : '\n';
                }

                returnValue += address;
            }
        });

        return returnValue;
    },

    formatContact(lead) {
        const contact = [];

        if (!lead.valid_lead_phone) {
            contact.push('withoutPhone');
        } else if (lead.valid_lead_phone.valid) {
            contact.push('withPhone');
        }

        if (!lead.valid_lead_email) {
            contact.push('withoutEmail');
        } else if (lead.valid_lead_email.valid) {
            contact.push('withEmail');
        }

        const connectedCommunications = sort(
            lead.communications.filter(c => c.connected),
            'created_at',
            false,
        );

        if (connectedCommunications.length) {
            if (connectedCommunications[0].communication_type_id == CommunicationType.OUTGOING) {
                contact.push('answered');
            }

            if (connectedCommunications[0].communication_type_id == CommunicationType.INCOMING) {
                contact.push('waiting');
            }
        }

        return contact.join(' ');
    },

    formatImportedCampaign(lead) {
        return `<span>${lead.imported_campaign || '-'}</span>`;
    },

    formatCity(lead) {
        return `<span>${lead.city || '-'}</span>`;
    },

    formatCivility(lead) {
        if (isEmpty(lead.civility) || lead.civility == 'none') {
            return '-';
        }

        return Civility.getTranslation(lead.civility);
    },

    formatProvince(lead) {
        return `<span>${lead.province || '-'}</span>`;
    },

    formatCountry(lead) {
        return `<span>${lead.country || '-'}</span>`;
    },

    formatPostalCode(lead) {
        return `<span>${lead.postal_code || '-'}</span>`;
    },

    formatDivision(lead) {
        if (!lead.division_id) {
            return '-';
        }

        return Division.getTranslation(lead.division_id);
    },

    formatReferrer(lead) {
        return `<span>${lead.referrer || '-'}</span>`;
    },

    formatQualification(lead) {
        return `<span>${lead.qualification || '-'}</span>`;
    },

    formatSearchTerm(lead) {
        return `<span>${lead.search_term || '-'}</span>`;
    },

    formatKeyword(lead) {
        return `<span>${lead.keyword || '-'}</span>`;
    },

    formatServiceIntention(lead) {
        const values = get(lead, 'service_process.intention.value');

        if (!values) {
            return '-';
        }

        return values.map(intention => {
            const value = i18n.t(`service.categories.${intention}`);
            return `<span>${value}</span>`;
        }).join('<br>') || '-';
    },

    formatServiceTransport(lead) {
        const value = get(lead, 'service_process.transport.value');
        return value ? `<span>${i18n.t(`clientCard.services.transports.${value}`)}<span>` : '-';
    },

    formatServiceWalkaround(lead) {
        const values = get(lead, 'service_process.walk_around.value');

        if (!values) {
            return '-';
        }

        return values.map(walkAround => {
            const value = i18n.t(`clientCard.services.walkArounds.${walkAround}`);
            return `<span>${value}</span>`;
        }).join('<br>') || '-';
    },

    formatUserDivisions(user, html = false) {
        let returnValue = '';

        for (let index = 0; index < user.divisions.length; index++) {
            const division = user.divisions[index];
            if (returnValue != '') {
                returnValue += html ? '<br>' : ', ';
            }

            returnValue += i18n.t(`divisions.${camelCase(division.name)}`);
        }

        return returnValue;
    },

    formatVehicleCategory(lead, type = 'wanted') {
        let values = [];
        let stringValues = [];
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        for (const vehicle of vehicles) {
            const value = vehicle.category?.trim() || 'none';

            values.push(value);
            stringValues.push(value !== 'none' ? i18n.t(`clientCard.vehicles.${value}`) : '-');
        }

        values = values.join('\n');
        stringValues = stringValues.join('<br>') || '-';

        return `<span data-export="${values}">${stringValues}</span>`;
    },

    formatVehicleCategoryRV(lead, type = 'wanted') {
        let values = [];
        let stringValues = [];
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        for (const vehicle of vehicles) {
            const value = vehicle.category_rv?.trim() || 'none';

            values.push(value);
            stringValues.push(value !== 'none' ? i18n.t(`clientCard.vehicles.${value}`) : '-');
        }

        values = values.join('\n');
        stringValues = stringValues.join('<br>') || '-';

        return `<span data-export="${values}">${stringValues}</span>`;
    },

    formatVehicleMechanical(lead, type = 'wanted') {
        let values = [];
        let stringValues = [];
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        for (const vehicle of vehicles) {
            const value = vehicle.mechanical?.trim() || 'none';

            values.push(value);
            stringValues.push(value !== 'none' ? i18n.t(`clientCard.vehicles.${value}`) : '-');
        }

        values = values.join('\n');
        stringValues = stringValues.join('<br>') || '-';

        return `<span data-export="${values}">${stringValues}</span>`;
    },

    formatVehicleVin(lead, type = 'wanted') {
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        const values =
            vehicles
                .map(v => {
                    return (v.vin || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleTransmission(lead, type = 'wanted') {
        let values = [];
        let stringValues = [];
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        for (const vehicle of vehicles) {
            const value = vehicle.transmission?.trim() || 'none';
            const lowerCasedValue = value.toLowerCase();

            if (['auto', 'manual', 'sequential'].includes(lowerCasedValue)) {
                stringValues.push(i18n.t(`clientCard.vehicles.${lowerCasedValue}`));
            } else if (value === 'none') {
                stringValues.push('-');
            } else {
                stringValues.push(value);
            }
        }

        values = values.join('\n');
        stringValues = stringValues.join('<br>') || '-';

        return `<span data-export="${values}">${stringValues}</span>`;
    },

    formatVehicleLength(lead, type = 'wanted', operator = 'min') {
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        const values =
            vehicles
                .map(v => {
                    return String(v[`length_${operator}`] || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleWeight(lead, type = 'wanted') {
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        const values = vehicles.map(vehicle => {
            const value = vehicle.weight?.trim() || 'none';
            return value !== 'none' ? value : '-';
        });

        return `<span>${values.length ? values.join('<br>') : '-'}</span>`;
    },

    formatVehicleTireType(lead, type = 'exchange') {
        let stringValues = [];
        let values = [];
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        for (const vehicle of vehicles) {
            let vehicleTireTypesStringValues = '';
            let vehicleTireTypesValues = '';

            if (vehicle.tire_type && typeof vehicle.tire_type === 'object') {
                vehicle.tire_type.forEach((tireType, key) => {
                    vehicleTireTypesStringValues += i18n.t(`clientCard.vehicles.tireType.${tireType}`);
                    vehicleTireTypesValues += tireType;

                    if (vehicle.tire_type.length > 1 && key != vehicle.tire_type.length - 1) {
                        vehicleTireTypesStringValues += ', ';
                    }
                });

                stringValues.push(vehicleTireTypesStringValues);
                values.push(vehicleTireTypesValues);
            } else {
                stringValues.push('-');
                values.push('none');
            }
        }

        values = values.join('\n') || 'none';
        stringValues = stringValues.join('<br>') || '-';

        return `<span data-export="${values}">${stringValues}</span>`;
    },

    formatVehicleModality(lead, dashboardType, type = 'wanted') {
        let values = [];
        let stringValues = [];
        let tooltip = '';
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        for (const vehicle of vehicles) {
            values.push((vehicle.modality || '-').trim());
            stringValues.push(
                ((vehicle.modality && i18n.t(`clientCard.vehicles.${camelCase(vehicle.modality)}`)) || '-').trim(),
            );
        }

        if (dashboardType == 'renewal' && values.find(v => v != '-')) {
            tooltip = i18n.t('renewal.modality');
        }

        values = values.join('\n') || '-';
        stringValues = stringValues.join('<br>') || '-';

        return `<span data-export="${values}" data-tooltip="${tooltip}">${stringValues}</span>`;
    },

    formatVehicleMake(lead, type = 'wanted') {
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        const values =
            vehicles
                .map(v => {
                    return String(v.make || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleTrim(lead, type = 'wanted') {
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        const values =
            vehicles
                .map(v => {
                    return String(v.trim || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleModel(lead, type = 'wanted') {
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        const values =
            vehicles
                .map(v => {
                    return String(v.model || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleYear(lead, type = 'wanted') {
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        const values =
            vehicles
                .map(v => {
                    const year = v.year != '0000' ? v.year : '';
                    return String(year || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleWanted(lead, html = true) {
        const values =
            getVehicles(lead)
                .map(vehicle => {
                    const value = [];

                    if (vehicle.model) {
                        value.push(vehicle.model);
                    }

                    if (vehicle.year && vehicle.year != '0000') {
                        value.push(vehicle.year);
                    }

                    return value.join(' ').trim() || '-';
                })
                .join('<br>') || '-';

        return html
            ? `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`
            : values.replace(/<br>/g, ', ');
    },

    formatVehicleWantedOffer(lead) {
        let vehicles = getVehicles(lead).filter(v => v.sold);
        vehicles = vehicles.length === 0 ? getVehicles(lead) : vehicles;

        const values =
            vehicles
                .map(v => {
                    return (v.offer_number || v.offer || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleWantedSuffix(lead) {
        let vehicles = getVehicles(lead).filter(v => v.sold);
        vehicles = vehicles.length === 0 ? getVehicles(lead) : vehicles;

        const values =
            vehicles
                .map(v => {
                    return (v.suffix || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleColor(lead, vehicleType, colorType) {
        const allVehicles = getVehicles(lead, vehicleType);
        let vehicles = allVehicles.filter(v => v.sold);
        vehicles = vehicles.length === 0 ? allVehicles : vehicles;

        const values = vehicles.map(v => {
            return (v[colorType] || '-').trim();
        }).join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleCertified(lead, type) {
        const allVehicles = getVehicles(lead, type);
        let vehicles = allVehicles.filter(v => v.sold);
        vehicles = vehicles.length === 0 ? allVehicles : vehicles;

        const values = vehicles.map(v => {
            const certified = v.certified ? 'yes' : 'no';
            return i18n.t(`general.${certified}`);
        }).join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatEvaluated(lead) {
        const value = get(lead, 'evaluated');
        return value ? `<span>${i18n.t('general.yes')}<span>` : `<span>${i18n.t('general.no')}<span>`;
    },

    formatVehicleExchangeCalculatorAppraiser(lead) {
        const values =
            getVehicles(lead, 'exchange').map(v => {
                return (v.calculator_appraiser || '-').trim();
            }).join('<br>') || '-';
        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeCondition(lead) {
        const allVehicles = getVehicles(lead, 'exchange');
        let vehicles = allVehicles.filter(v => v.sold);
        vehicles = vehicles.length === 0 ? allVehicles : vehicles;

        const values = vehicles.map(v => {
            if (isEmpty(v.condition) || v.condition == 'none') {
                return '-';
            }
            return LeadVehicleCondition.getTranslation(v.condition).trim();
        }).join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleFuel(lead, type = 'wanted') {
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        const values = vehicles.map(v => {
            if (isEmpty(v.fuel) || v.fuel == 'none') {
                return '-';
            }
            return LeadVehicleFuel.getTranslation(v.fuel).trim();
        }).join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleWantedPrice(lead) {
        let vehicles = getVehicles(lead).filter(v => v.sold);
        vehicles = vehicles.length === 0 ? getVehicles(lead) : vehicles;

        const values =
            vehicles
                .map(v => {
                    return ((v.price && toMoney(v.price)) || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleWantedBudget(lead, operator = 'min') {
        let vehicles = getVehicles(lead).filter(v => v.sold);
        vehicles = vehicles.length === 0 ? getVehicles(lead) : vehicles;

        const values =
            vehicles
                .map(v => {
                    return ((v[`budget_${operator}`] && toMoney(v[`budget_${operator}`])) || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleWantedProfit(lead, authUser) {
        const shouldShowProfit = (
            !authUser ||
            authUser.isAdmin() ||
            (lead.account.sale_table_options && !lead.account.sale_table_options.hide_profit) ||
            (authUser.isDirector() || authUser.isCommercialDirector()) ||
            (authUser.isAdvisor() && authUser.id === lead.user_id) ||
            (authUser.id === lead.bdc_user_id && (authUser.isBdc() || authUser.isBdcDirector()))
        );

        let vehicles = getVehicles(lead).filter(v => v.sold);
        vehicles = vehicles.length === 0 ? getVehicles(lead) : vehicles;

        const values =
            vehicles
                .map(v => {
                    return ((shouldShowProfit && v.profit && toMoney(v.profit)) || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleWantedVerified(lead) {
        let vehicles = getVehicles(lead, type);

        const soldVehicles = vehicles.filter(v => v.sold);
        vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;

        return vehicles.some(v => v.sold && v.verified_by_id) ? 'yes' : 'no';
    },

    formatVehicleWantedTire(lead) {
        const vehicle = lead.getSoldOrFirstWantedVehicle();

        return !empty(vehicle) && vehicle.tire ? 'yes' : 'no';
    },

    formatVehicleWantedStockState(lead, html = true) {
        let dataFilter = '';
        let vehicles = getVehicles(lead).filter(v => v.sold);
        vehicles = vehicles.length === 0 ? getVehicles(lead) : vehicles;

        const values =
            vehicles
                .map(vehicle => {
                    let label = 'label-default';
                    let translatedStockState = '-';
                    const stockState = vehicle.stock_state || 'none';

                    dataFilter += `${stockState}\n`;

                    if (stockState !== 'none') {
                        translatedStockState = i18n.t(`saleTable.${stockState}`);
                    }

                    if (vehicle.stock_state === 'stock') {
                        label = 'label-success';
                        translatedStockState = i18n.t('saleTable.inStock');
                    }

                    return `<div class="label ${label} | inline-block my-0.5 min-w-20">${translatedStockState}</div>`;
                })
                .join('<br>') || '-';

        if (values == '-') {
            dataFilter = 'none';
        }

        return html
            ? `<div data-export="${dataFilter.replace(/<br>/g, ',')}">${values}</div>`
            : values.replace(/<br>/g, ', ');
    },

    formatVehicleWantedStockNumber(lead) {
        let vehicles = getVehicles(lead).filter(v => v.sold);
        vehicles = vehicles.length === 0 ? getVehicles(lead) : vehicles;

        const values =
            vehicles
                .map(v => {
                    return (v.stock_state && v.stock ? v.stock : '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.trim().replace(/<br>/g, ',')}">${values}</span>`;
    },

    formatVehicleExchangeTradeType(lead) {
        let values = [];
        let stringValues = [];

        let vehicles = getVehicles(lead, 'exchange');

        const soldVehicles = vehicles.filter(v => v.sold);
        vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;

        for (const vehicle of vehicles) {
            const value = vehicle.trade_type?.trim() || 'none';

            values.push(value);
            stringValues.push(value !== 'none' ? i18n.t(`clientCard.vehicles.tradeTypes.${value}`) : '-');
        }

        values = values.join('\n');
        stringValues = stringValues.join('<br>') || '-';

        return `<span data-export="${values}">${stringValues}</span>`;
    },

    formatVehicleExchangePayment(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(v => {
                    return ((v.payment && toMoney(v.payment)) || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangePaymentWithTax(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(v => {
                    return ((v.payment_with_tax && toMoney(v.payment_with_tax)) || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeAccidented(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(v => {
                    return ((v.accidented_damage_cost && toMoney(v.accidented_damage_cost)) || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeAllowedKm(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(v => {
                    return v.allowed_mileage ? `${v.allowed_mileage} KM` : '-';
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeMoRemaining(lead) {
        const now = new Date();

        const values =
            getVehicles(lead, 'exchange')
                .map(vechicle => {
                    if (!vechicle.end_contract_date) {
                        return '-';
                    }

                    const endContractDate = new ActivixDate(vechicle.end_contract_date);
                    const diffInMonths = endContractDate.diffInMonths(now);
                    const monthsRemaining = diffInMonths > 0 ? diffInMonths : 0;

                    return `${monthsRemaining} ${i18n.t('clientCard.vehicles.months')}`;
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeFrequency(lead) {
        let values = [];
        let stringValues = [];

        for (const vehicle of getVehicles(lead, 'exchange')) {
            const value = (vehicle.frequency || 'none').toLowerCase().trim();

            if (value != 'none') {
                values.push(value);
                stringValues.push(i18n.t(`filters.vehicle_frequency.${value}`));
            }
        }

        values = values.join('\n') || '-';
        stringValues = stringValues.join('<br>') || '-';

        return `<span data-export="${values}">${stringValues}</span>`;
    },

    formatVehicleExchangeValue(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(vehicle => {
                    const vehicleValue = LeadVehicle.calculateVehicleValue(vehicle);

                    if (vehicle.value_min && vehicle.value_max && vehicle.vin_match == VehicleVinMatchType.PARTIAL) {
                        return `${toMoney(vehicle.value_min)} - ${toMoney(vehicle.value_max)}`;
                    }

                    return (vehicleValue ? toMoney(vehicleValue) : '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleActualValue(lead, type) {
        const values = getVehicles(lead, type).map(v => {
            return v.actual_value ? `${v.actual_value} $` : '-';
        }).join('<br>') || '-';
        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeSupplierValue(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(vehicle => {
                    return (toMoney(vehicle.calculator_value) || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeBalance(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(v => {
                    if (v.frequency != 'none' && v.payment) {
                        return toMoney(v.balance);
                    }

                    return ((v.balance && toMoney(v.balance)) || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeResidual(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(v => {
                    return ((v.residual && toMoney(v.residual)) || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeEquity(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(v => {
                    return toMoney(LeadVehicle.calculateEquity(v)) || '-';
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeMileage(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(v => {
                    return (v.mileage || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeWarranty(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(v => {
                    return (v.warranty || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeIntention(lead) {
        let values = [];
        let stringValues = [];

        for (const vehicle of getVehicles(lead, 'exchange')) {
            const value = camelCase(vehicle.intention?.trim() || 'none');

            values.push(value);
            stringValues.push(value !== 'none' ? i18n.t(`clientCard.vehicles.${value}`) : '-');
        }

        values = values.join('\n');
        stringValues = stringValues.join('<br>') || '-';

        return `<span data-export="${values}">${stringValues}</span>`;
    },

    formatVehicleEndContract(lead, type) {
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        const values =
            vehicles
                .map(v => {
                    return new ActivixDate(v.end_contract_date, 'date').toHumanShort() || '-';
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeSoldBy(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(v => {
                    return (v.sold_by || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangePurchaseDate(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(v => {
                    return new ActivixDate(v.purchase_date).toHumanShort() || '-';
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleEndWarrantyDate(lead, type = 'exchange') {
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        const values =
            vehicles
                .map(v => {
                    return new ActivixDate(v.end_warranty_date, 'date').toHumanShort() || '-';
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeExtendedWarranty(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(v => {
                    return (v.extended_warranty || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeLicensePlate(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(v => {
                    return (v.license_plate || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleExchangeRecall(lead) {
        const values =
            getVehicles(lead, 'exchange')
                .map(v => {
                    return (v.recall || '-').trim();
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n')}">${values}</span>`;
    },

    formatVehicleRate(lead, type = 'wanted') {
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        const values =
            vehicles
                .map(v => {
                    return v.rate != null ? `${v.rate} %` : '-';
                })
                .join('<br>') || '-';

        return `<span data-export="${values.replace(/<br>/g, '\n').replace(' %', '')}">${values}</span>`;
    },

    formatWebBoostResult(lead) {
        const firstCommunication = lead.communications[0];

        const reviveCommunication = lead.communications.find(communication => {
            return (
                !!communication.url &&
                communication.service_id == Service.REVIVE &&
                communication.duration_reached !== null
            );
        });

        const isConsideredReached =
            !!lead.appointment_date ||
            !!lead.sale_date ||
            (lead.created_by_user && lead.lead_type_id == LeadType.PHONE);

        const results = {
            answered: 'reached',
            pending: 'pendingWb',
            calling: 'calling',
            invalid: 'invalid',
            attempted: 'attempted',
            error: 'error',
            interrupted: 'interrupted',
        };

        if (!firstCommunication) {
            return isConsideredReached ? 'reached' : 'unknown';
        }

        if (firstCommunication.status && results[firstCommunication.status]) {
            return results[firstCommunication.status];
        }

        if (reviveCommunication) {
            return 'revive';
        }

        if (firstCommunication.user_id) {
            return 'canceled';
        }

        return 'missed';
    },

    formatAgents(lead, html = true) {
        let returnValue = '-';
        const user = lead.user_id ? lead.user : null;
        const bdcUser = lead.bdc_user_id ? lead.bdc_user : null;
        let exportString = '';
        let valueString = '';
        let ids = '';

        if (lead.account.bdc_advisor) {
            if (user) {
                exportString += getFullName(user);
                valueString += getFullName(user);
                ids += user.id;
            }

            if (bdcUser) {
                if (exportString) {
                    exportString += '\n';
                }

                if (valueString) {
                    valueString += '<br>';
                }

                exportString += `${getFullName(bdcUser)} [BDC]`;
                valueString += `${getFullName(bdcUser)} [BDC]`;
                ids += bdcUser.id;
            }

            if (user || bdcUser) {
                returnValue = html
                    ? `
                    <span data-export="${exportString}" data-user-id="${ids}">
                        ${valueString}
                    </span>
                `
                    : valueString;
            }
        } else if (user) {
            returnValue = html
                ? `
                    <span data-export="${getFullName(user)}" data-user-id="${user.id}">
                        ${getFullName(user)}
                    </span>
                `
                : getFullName(user);
        }

        const receptionistCommunication = lead.communications.some(communication => communication.receptionist);

        if (
            (returnValue == '—' || (!user && !bdcUser)) &&
            lead.communications &&
            lead.communications.length > 0 &&
            receptionistCommunication
        ) {
            return html
                ? `
                <span data-export="" data-user-id="none">
                    ${i18n.t('divisions.reception')}
                </span>
            `
                : i18n.t('divisions.reception');
        }

        return returnValue;
    },

    formatTakeOverDirector(lead) {
        if (!lead.take_over_director_id) {
            return '-';
        }

        return `
            <span
                data-export="${getFullName(lead.take_over_director, false)}"
                data-user-id="${lead.take_over_director.id}"
            >
                ${getFullName(lead.take_over_director)}
            </span>
        `;
    },

    formatAccountName(lead, html = false) {
        const accountName = lead.account_id ? lead.account.name : '-';

        return html ? `<span>${accountName}</span>` : accountName;
    },

    formatDate(lead, startDate, endDate, dashboardType = '', html = true) {
        // When modifying this code, also modify the "case 'date'" section of leadFilterCriteria.php
        let name = '';
        let date = `<span class="text-red-500">${i18n.t('date.invalidDate')}</span>`;
        let dateExport = i18n.t('date.invalidDate');
        let tooltip = '';
        let colorClass = '';

        if (dashboardType == 'saleTable' || dashboardType == 'commercial') {
            if (lead.sale_date) {
                const saleDate = new ActivixDate(lead.sale_date, 'date');

                date = saleDate.toHumanShort();
                dateExport = saleDate.format('dd MMM');
            }
        } else if (lead.lead_type_id == LeadType.WALK_IN || dashboardType == 'walkIn') {
            let dateColumn = 'created_at';
            name = i18n.t('date.createdDate');

            if (lead.presented_date) {
                dateColumn = 'presented_date';
                name = i18n.t('date.presentedDate');
            } else if (lead.appointment_date) {
                dateColumn = 'appointment_date';
                name = i18n.t('date.appointmentDate');
            }

            if (lead[dateColumn]) {
                const presentedDate = new ActivixDate(lead[dateColumn]);

                if (!presentedDate.isBetween(startDate, endDate)) {
                    colorClass = 'text-blue-500';
                    name += `<br>${i18n.t('dashboards.dateNotInRange')}`;
                }

                date = presentedDate.toHumanShort();
                dateExport = presentedDate.format('dd MMM');
            }
        } else if (lead.lead_type_id == LeadType.PHONE || dashboardType == 'phoneUp') {
            name = i18n.t('date.callDate');

            if (lead.call_date) {
                const callDate = new ActivixDate(lead.call_date);

                if (!callDate.isBetween(startDate, endDate)) {
                    colorClass = 'text-blue-500';
                    name += `<br>${i18n.t('dashboards.dateNotInRange')}`;
                }

                date = callDate.toHumanShort();
                dateExport = callDate.format('dd MMM');
            }
        } else if (lead.lead_type_id == LeadType.RENEWAL && dashboardType == 'allLead') {
            name = i18n.t('date.endContractDate');

            if (lead.end_contract_date) {
                const endContractDate = new ActivixDate(lead.end_contract_date, 'date');

                if (!endContractDate.isBetween(startDate, endDate)) {
                    colorClass = 'text-blue-500';
                    name += `<br>${i18n.t('dashboards.dateNotInRange')}`;
                }

                date = endContractDate.toHumanShort();
                dateExport = endContractDate.format('dd MMM');
            }

            if (dateExport == i18n.t('date.invalidDate')) {
                date = '<span class="text-red-500">-</span>';
                dateExport = '-';
            }
        } else if (lead.lead_type_id == LeadType.DMS && dashboardType == 'service') {
            name = i18n.t('date.appointmentDate');

            if (lead.appointment_date) {
                const appointmentDate = new ActivixDate(lead.appointment_date, 'date');

                if (!appointmentDate.isBetween(startDate, endDate)) {
                    colorClass = 'text-primary';
                    name += `<br>${i18n.t('dashboards.dateNotInRange')}`;
                }

                date = appointmentDate.toHumanShort();
                dateExport = appointmentDate.format('dd MMM');
            }

            if (dateExport == i18n.t('date.invalidDate')) {
                date = '<span class="text-red">-</span>';
                dateExport = '-';
            }
        } else {
            name = i18n.t('date.createdDate');

            if (lead.created_at) {
                const createdAt = new ActivixDate(lead.created_at);

                if (!createdAt.isBetween(startDate, endDate)) {
                    colorClass = 'text-blue-500';
                    name += `<br>${i18n.t('dashboards.dateNotInRange')}`;
                }

                date = createdAt.toHumanShort();
                dateExport = createdAt.format('dd MMM');
            }
        }

        if (dashboardType && dashboardType == 'allLead') {
            tooltip = `data-tooltip="${name}"`;
        } else if (dashboardType && dashboardType == 'renewal') {
            tooltip = `data-tooltip="${i18n.t('date.createdDate')}"`;
        }

        const exportedDates = dateExport.replace(/<br>/g, '\n');

        if (html) {
            return `
                <span ${tooltip} data-export="${exportedDates}" class="${colorClass}">
                    ${date}
                </span>
            `;
        }

        return date;
    },

    formatCampaign(lead, type) {
        if (!lead.campaigns.length) {
            return '<span>-</span>';
        }

        const campaign = lead.campaigns.find(campaign => campaign.type === type);

        return `<span>${!isEmpty(campaign) ? campaign.name : '-'}</span>`;
    },

    formatEventSegment(lead) {
        const value = lead.segment || '-';
        const stringValue = lead.segment ? i18n.t(`dashboards.segments.${lead.segment}`) : '-';

        return `<span data-export="${value}">${stringValue}</span>`;
    },

    formatAppointment(lead, filterOptions) {
        return (filterOptions || []).filter(option => {
            if (!['no', 'noDate', 'toReach', 'uncertain', 'notCompleted'].includes(option) && !lead.appointment_date) {
                return false;
            }

            switch (option) {
                case 'yes':
                    return !!lead.appointment_date;

                case 'no':
                    return !lead.appointment_date;

                case 'noTime':
                    return new ActivixDate(lead.appointment_date).isStartOfDay();

                case 'notCompleted': {
                    const noCompletedAppointment = lead.task_events.find(taskEvent => {
                        return taskEvent.main_event && taskEvent.task_event_type_id == TaskEventType.APPOINTMENT;
                    });

                    return !!noCompletedAppointment && !noCompletedAppointment.status;
                }
                case 'noShow': {
                    const noShowAppointment = lead.task_events.find(taskEvent => {
                        return taskEvent.main_event && taskEvent.task_event_type_id == TaskEventType.APPOINTMENT;
                    });

                    return !!noShowAppointment && noShowAppointment.no_show;
                }
                case 'confirmed': {
                    const confirmedAppt = lead.task_events.find(taskEvent => {
                        return taskEvent.main_event && taskEvent.task_event_type_id == TaskEventType.APPOINTMENT;
                    });

                    return !!confirmedAppt && confirmedAppt.confirmed;
                }
                case 'canceled': {
                    const canceledAppt = lead.task_events.find(taskEvent => {
                        return taskEvent.main_event && taskEvent.task_event_type_id == TaskEventType.APPOINTMENT;
                    });

                    return !!canceledAppt && canceledAppt.canceled;
                }
                case 'notConfirmed': {
                    const notConfirmedAppt = lead.task_events.find(taskEvent => {
                        return taskEvent.main_event && taskEvent.task_event_type_id == TaskEventType.APPOINTMENT;
                    });

                    return !!notConfirmedAppt && !notConfirmedAppt.canceled && !notConfirmedAppt.confirmed;
                }
                case 'noDate':
                    return !lead.appointment_date && lead.last_dialogs_status == 'PE';

                case 'toReach':
                    return !lead.appointment_date && lead.last_dialogs_status == 'RE19';

                case 'uncertain':
                    return !lead.appointment_date && lead.last_dialogs_status == 'VR';

                default:
                    return false;
            }
        });
    },

    formatPhoneAppointment(lead, filterOptions) {
        return (filterOptions || []).filter(option => {
            if (!['no', 'noDate', 'toReach', 'uncertain', 'notCompleted'].includes(option) && !lead.appt_call_date) {
                return false;
            }

            switch (option) {
                case 'yes':
                    return !!lead.appt_call_date;

                case 'no':
                    return !lead.appt_call_date;

                case 'noTime':
                    return new ActivixDate(lead.appt_call_date).isStartOfDay();

                case 'notCompleted': {
                    const noCompletedPhoneAppointment = lead.task_events.find(taskEvent => {
                        return taskEvent.main_event && taskEvent.task_event_type_id == TaskEventType.PHONE_APPOINTMENT;
                    });

                    return !!noCompletedPhoneAppointment && !noCompletedPhoneAppointment.status;
                }
                case 'confirmed': {
                    const confirmePhoneAppointment = lead.task_events.find(taskEvent => {
                        return taskEvent.main_event && taskEvent.task_event_type_id == TaskEventType.PHONE_APPOINTMENT;
                    });

                    return !!confirmePhoneAppointment && confirmePhoneAppointment.confirmed;
                }
                case 'canceled': {
                    const canceledPhoneAppointment = lead.task_events.find(taskEvent => {
                        return taskEvent.main_event && taskEvent.task_event_type_id == TaskEventType.PHONE_APPOINTMENT;
                    });

                    return !!canceledPhoneAppointment && canceledPhoneAppointment.canceled;
                }
                case 'notConfirmed': {
                    const notConfirmedPhoneAppointment = lead.task_events.find(taskEvent => {
                        return taskEvent.main_event && taskEvent.task_event_type_id == TaskEventType.PHONE_APPOINTMENT;
                    });

                    return (
                        !!notConfirmedPhoneAppointment &&
                        !notConfirmedPhoneAppointment.canceled &&
                        !notConfirmedPhoneAppointment.confirmed
                    );
                }
                case 'noDate':
                    return !lead.appt_call_date && lead.last_dialogs_status == 'PE';

                case 'toReach':
                    return !lead.appt_call_date && lead.last_dialogs_status == 'RE19';

                case 'uncertain':
                    return !lead.appt_call_date && lead.last_dialogs_status == 'VR';

                default:
                    return false;
            }
        });
    },

    formatFollowUp(lead, followUpType, nextFollowUpType, lastFollowUpType, filterOption, startDate, endDate) {
        if (
            followUpType == 'presented' &&
            ((lead.lead_type_id == LeadType.WALK_IN && lead.division_id != Division.SERVICE) ||
                (lead.division_id == Division.SERVICE && lead.presented_date))
        ) {
            return dateFilterPositiveFormater(filterOption, new ActivixDate(lead.presented_date), startDate, endDate);
        }

        if (['invalid', 'lost', 'duplicate'].includes(lead.status) && lead[followUpType]) {
            return dateFilterPositiveFormater(
                filterOption,
                new ActivixDate(lead[`${followUpType}_date`]),
                startDate,
                endDate,
            );
        }

        if ((followUpType == 'sale' && lead.refinanced_date) || (followUpType == 'refinanced' && lead.sale_date)) {
            return 'no';
        }

        if (
            followUpType == 'sale' &&
            ((lastFollowUpType && !!lead.sale_date) || (!lastFollowUpType && lead.sale_by_phone))
        ) {
            return dateFilterPositiveFormater(
                filterOption,
                new ActivixDate(lead.sale_date, 'date'),
                startDate,
                endDate,
            );
        }

        if (followUpType == 'refinanced' && lead[followUpType] && filterOption) {
            switch (filterOption) {
                case 'financed':
                    return lead.refinanced_type == 'financed' ? 'financed' : 'no';
                case 'cash':
                    return lead.refinanced_type == 'cash' ? 'cash' : 'no';
            }
        }

        if (lead[followUpType]) {
            return dateFilterPositiveFormater(
                filterOption,
                new ActivixDate(lead[`${followUpType}_date`]),
                startDate,
                endDate,
            );
        }

        return 'no';
    },

    formatDateFilterMultiselectFormater(lead, filterOptions, startDate, endDate) {
        const vehicle = getVehicles(lead).find(v => !!v.recorded_date);
        const value = [];

        if (vehicle && vehicle.recorded_date) {
            const recordedDate = new ActivixDate(vehicle.recorded_date, 'date');

            if (
                filterOptions.includes('yes') ||
                (filterOptions.includes('inside_dates') && filterOptions.includes('outside_dates'))
            ) {
                value.push('yes');
            }

            if (filterOptions.includes('inside_dates') && recordedDate.isBetween(startDate, endDate)) {
                value.push('inside_dates');
            }

            if (filterOptions.includes('outside_dates') && !recordedDate.isBetween(startDate, endDate)) {
                value.push('outside_dates');
            }
        } else if (filterOptions.includes('no')) {
            value.push('no');
        }

        return value;
    },

    formatPhoneOrigin(lead) {
        const communication = lead.communications.find(c => c.from_phone);
        return communication ? communication.from_phone : '';
    },

    formatPhoneDestination(lead) {
        const communication = lead.communications.find(c => c.to_phone);
        return communication ? communication.to_phone : '';
    },

    formatStatus(lead) {
        if (['duplicate', 'invalid', 'lost'].includes(lead.status)) {
            return lead.status;
        }

        return 'none';
    },

    formatType(lead) {
        switch (lead.lead_type_id) {
            case LeadType.EMAIL:
                return i18n.t('dashboards.webLead');
            case LeadType.PHONE:
                return i18n.t('dashboards.phoneUp');
            case LeadType.WALK_IN:
                return i18n.t('dashboards.walkIn');
            case LeadType.LOYALTY:
                return i18n.t('dashboards.loyalty');
            case LeadType.RENEWAL:
                return i18n.t('dashboards.renewalAbr');
            case LeadType.SMS:
                return i18n.t('dashboards.sms');
            case LeadType.EVENT:
                return i18n.t('dashboards.event');
            case LeadType.PRE_BOOKING:
                return i18n.t('dashboards.prebooking');
            case LeadType.DMS:
                return i18n.t('dashboards.dms');
            case LeadType.WEB_ORDER:
                return i18n.t('dashboards.webOrder');
        }

        return '';
    },

    formatListening(lead, authUser, dashboardType) {
        const sorting = dashboardType == 'webBoost' ? 'asc' : 'desc';
        const communications = orderBy(lead.communications, ['id'], [sorting]);

        if (communications.length > 0) {
            for (let index = 0; index < communications.length; index++) {
                const communication = communications[index];
                if (
                    communication.url &&
                    !communication.created_by_user &&
                    communication.communication_method_id != CommunicationMethod.MESSENGER
                ) {
                    return 'yes';
                }
                if (
                    communication.created_by_user &&
                    communication.communication_method_id == CommunicationMethod.PHONE
                ) {
                    return 'yes';
                }
            }
        }

        return 'no';
    },

    formatFollowed(lead) {
        let countEmail = 0;
        let countCall = 0;
        let countSms = 0;
        let returnValue = '';

        for (let index = 0; index < lead.communications.length; index++) {
            const communication = lead.communications[index];

            const validOutbound = communication.connected && (communication.communication_type_id == CommunicationType.OUTGOING || communication.outbound_counter);
            const validInbound = communication.communication_type_id == CommunicationType.INCOMING && communication.exchange_counter > 1;

            if (validOutbound || validInbound) {
                switch (communication.communication_method_id) {
                    case CommunicationMethod.PHONE:
                        countCall++;
                        break;

                    case CommunicationMethod.EMAIL:
                        countEmail++;
                        break;

                    case CommunicationMethod.SMS:
                        countSms++;
                        break;
                }
            }
        }

        if (countEmail == 0 && countCall == 0 && countSms == 0) {
            return 'none\\noCall\\noEmail\\noSms';
        }

        returnValue += countCall ? 'withCall' : 'noCall';
        returnValue += countEmail ? 'withEmail' : 'noEmail';
        returnValue += countSms ? 'withSms' : 'noSms';

        return returnValue;
    },

    formatUnsubscribe(lead) {
        if (lead.unsubscribe_all_date) {
            const unsubscribeAllDate = new ActivixDate(lead.unsubscribe_all_date, 'date');

            let tooltip = i18n.t('optOut.until', [unsubscribeAllDate.toHumanShort()]);

            if (unsubscribeAllDate.year == 2222) {
                tooltip = i18n.t('optOut.indefinitely');
            }

            return `
                <div data-export="dnd\ncall\nemail\nsms">
                    <div data-tooltip="${tooltip}" class="label label-default | flex items-center px-2 py-1 text-base">
                        ${getIconMarkup('regular/delete')}
                    </div>
                </div>
            `;
        }

        const returnValue = [];
        const exportValue = [];

        if (lead.unsubscribe_call_date) {
            const unsubscribeCallDate = new ActivixDate(lead.unsubscribe_call_date);
            const tooltip = i18n.t('optOut.since', [unsubscribeCallDate.toHumanShort()]);

            exportValue.push('call');
            returnValue.push(`
                <div data-tooltip="${tooltip}" class="label label-default | flex items-center px-2 py-1 ml-1 text-base">
                    ${getIconMarkup('regular/phone')}
                </div>
            `);
        }

        if (lead.unsubscribe_email_date) {
            const unsubscribeEmailDate = new ActivixDate(lead.unsubscribe_email_date);
            const tooltip = i18n.t('optOut.since', [unsubscribeEmailDate.toHumanShort()]);

            exportValue.push('email');
            returnValue.push(`
                <div data-tooltip="${tooltip}" class="label label-default | flex items-center px-2 py-1 ml-1 text-base">
                    ${getIconMarkup('regular/email-action-unread')}
                </div>
            `);
        }

        if (lead.unsubscribe_sms_date) {
            const unsubscribeSmsDate = new ActivixDate(lead.unsubscribe_sms_date);
            const tooltip = i18n.t('optOut.since', [unsubscribeSmsDate.toHumanShort()]);

            exportValue.push('sms');
            returnValue.push(`
                <div data-tooltip="${tooltip}" class="label label-default | flex items-center px-2 py-1 ml-1 text-base">
                    ${getIconMarkup('regular/messages-bubble-typing-1')}
                </div>
            `);
        }

        return `
            <div class="flex items-center -ml-1" data-export="${exportValue.join('\n') || 'none'}">
                ${returnValue.join('') || '-'}
            </div>
        `;
    },

    formatTodo(lead) {
        const uncompletedTasks = (lead.task_events || []).filter(task => {
            return !task.status && !task.canceled && !task.no_show;
        });

        if (!uncompletedTasks.length) {
            return 'none';
        }

        const nextTodo = orderBy(uncompletedTasks, 'start_at', 'asc')[0];
        const nextTodoStartAt = new ActivixDate(nextTodo.start_at);
        const isLate = nextTodoStartAt.isPast() ? 'late' : '';

        return `${nextTodo.task_event_type_id} ${isLate}`;
    },

    formatSource(lead, html = false) {
        const accountSource = lead.account.sources.find(p => p.id == lead.source_id);

        const source =
            lead.account.display_approved_sources && accountSource?.approved_source?.name || accountSource?.name || lead.source || lead.provider?.name || lead.import_source || lead.media_source;

        if (!source || source == 'none') {
            if (html) {
                return `<span data-export="${i18n.t('general.none')}">-</span>`;
            }

            return 'none';
        }

        if (lead.scraper_description) {
            if (html) {
                return `<a data-export="${source}" class="popover-source" data-id="${lead.id}">${source}</a>`;
            }

            return source;
        }

        if (html) {
            return `<span data-export="${source}">${source}</span>`;
        }

        return source;
    },

    formatProvider(lead, options = []) {
        let provider = lead.provider || '-';

        if (lead.provider === 'none') {
            provider = '-';
        }

        const providerOptions = options.find(option => option.value === lead.provider);

        if (providerOptions) {
            provider = providerOptions.text;
        }

        return `<span data-export="${provider}">${provider}</span>`;
    },

    formatLatestComment(lead, html = true) {
        if (!lead.latest_comment) {
            return '-';
        }

        if (html) {
            return `
                <span data-export="${lead.latest_comment.content}" class="user-comment flex justify-center items-center">
                    ${lead.latest_comment.content}
                </span>
            `;
        }

        return lead.latest_comment.content;
    },

    formatAvailableDate(lead) {
        if (lead.available_date) {
            const availableDate = new ActivixDate(lead.available_date);
            const availableDateString = availableDate.toHumanShort(false);

            return `
                <span data-export="${availableDateString}">
                    ${availableDateString}
                </span>
            `;
        }

        return '<span data-export="-">-</span>';
    },

    formatPaperworkDate(lead) {
        if (lead.paperwork_date) {
            const paperworkDate = new ActivixDate(lead.paperwork_date);
            const paperworkDateString = paperworkDate.toHumanShort(false);

            return `
                <span data-export="${paperworkDateString}">
                    ${paperworkDateString}
                </span>
            `;
        }

        return '<span data-export="-">-</span>';
    },

    formatDeliveredBy(lead) {
        const value = lead.delivered_by_id || 'none';
        const text = value !== 'none' ? getFullName(lead.delivered_by) : '-';

        return `
            <span data-export="${value}">
                ${text}
            </span>
        `;
    },

    formatCsiDate(lead) {
        if (lead.csi_date && lead.division_id != Division.USED) {
            const csiDate = new ActivixDate(lead.csi_date, 'date');
            const csiDateString = csiDate.toHumanShort();

            return `
                <span data-export="${csiDateString}">
                    ${csiDateString}
                </span>
            `;
        }

        return '<span data-export="-">-</span>';
    },

    formatCommercial(lead) {
        let returnValue = '-';

        if (lead.commercial_id) {
            returnValue = getFullName(lead.commercial);
        }

        return returnValue.trim();
    },

    formatCaslConsentLimitDate(lead) {
        const daysLeft = lead.customer.temporaryConsentDaysLeft;

        if (!daysLeft) {
            return '-';
        }

        const interval = intervalToDuration({ start: 0, end: daysLeft * 60 * 60 * 24 * 1000 });
        const unitsOrder = ['years', 'months', 'days'];
        let highestUnit = unitsOrder.find(unit => interval[unit]) || 'days';

        if (highestUnit === 'years') {
            interval.months = (interval.years * 12) + interval.months;
            highestUnit = 'months';
        }

        return `${interval[highestUnit]} ${i18n.tc(`consent.delays.${highestUnit}`, interval[highestUnit])}`;
    },

    formatLeadForm(lead, html = true) {
        let leadForm = '';

        if (!isEmpty(lead['lead_form:localized'])) {
            leadForm = lead['lead_form:localized'][i18n.locale] || '';
        } else if (isEmpty(lead.lead_form)) {
            leadForm = lead.form ? lead.form.trim() : '';
        } else if (!lead.lead_form.account_id) {
            leadForm =
                lead.lead_form[`display_name_${i18n.locale}`] || i18n.t(`leadForms.${lead.lead_form.name}`);
        } else {
            leadForm = lead.lead_form.name;
        }

        // Lead has no form
        if (!leadForm) {
            leadForm = '-';
        }

        if (!html) {
            return leadForm;
        }

        return `<span data-export="${leadForm}">${leadForm}</span>`;
    },

    formatVehicleInstitutionBank(lead, type = 'wanted') {
        let values = [];
        let stringValues = [];
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        for (const vehicle of vehicles) {
            const value = (vehicle.bank?.name || vehicle.bank || vehicle.imported_bank || 'none').trim();

            values.push(value);
            stringValues.push(value !== 'none' ? value : '-');
        }

        values = values.join('\n');
        stringValues = stringValues.join('<br>') || '-';

        return `<span data-export="${values}">${stringValues}</span>`;
    },

    formatInstitutionBank(lead) {
        const value = lead.lead_bank?.name || lead.bank || lead.imported_bank || '-';

        return `<span data-export="${value}">${value}</span>`;
    },

    formatProgressState(lead) {
        let labelClass = 'label-transparent border-none';

        const state = lead.progress_state;
        const text = state !== 'none' ? ProgressState.getTranslation(state) : '';

        switch (state) {
            case 'approved':
            case 'cash_deal':
                labelClass = 'label-success';
                break;

            case 'sent':
                labelClass = 'label-primary';
                break;

            case 'proof':
            case 'endorser':
            case 'cash':
            case 'vehicle_selection':
                labelClass = lead.approved ? 'label-success' : 'label-warning';
                break;

            case 'refused':
                labelClass = 'label-danger';
                break;
        }

        return `
            <div
                class="label ${labelClass} | block min-w-20"
                data-tooltip="${text}"
                data-export="${state}"
            >
                ${text || '-'}
            </div>
        `;
    },

    formatVehicleTerm(lead, type = 'wanted') {
        let values = [];
        let stringValues = [];
        let vehicles = getVehicles(lead, type);

        if (type == 'wanted') {
            const soldVehicles = vehicles.filter(v => v.sold);
            vehicles = soldVehicles.length === 0 ? vehicles : soldVehicles;
        }

        for (const vehicle of vehicles) {
            const value = vehicle.term || 'none';

            values.push(value);
            stringValues.push(value !== 'none' ? `${value} ${i18n.t('clientCard.vehicles.months')}` : '-');
        }

        values = values.join('\n');
        stringValues = stringValues.join('<br>') || '-';

        return `<span data-export="${values}">${stringValues}</span>`;
    },

    formatYesNo(lead, index) {
        if (lead[index]) {
            return 'yes';
        }

        return 'no';
    },

    formatLocale(lead) {
        return (lead.locale || '-').toUpperCase();
    },

    formatProgressWorkOrder(lead) {
        const value = lead.prepared_work_order || '-';

        return `<span data-export="${value}">${value}</span>`;
    },

    formatPerformance(lead) {
        let performance = '-';

        if (lead.csi_date) {
            performance = i18n.t('clientCard.csi');
        } else if (lead.delivered_date) {
            performance = i18n.t('result.delivered');
        } else if (lead.refinanced_date) {
            performance = i18n.t('accounts.edit.refinanced');
        } else if (lead.sale_by_phone || lead.sale_date) {
            performance = i18n.t('result.sale');
        } else if (lead.presented_date || lead.home_presented_date) {
            performance = i18n.t('result.presented');
        } else if (lead.appointment_date) {
            performance = i18n.t('result.appointment');
        }

        return performance;
    },

    formatCreatedMethod(lead) {
        const value = lead.created_method;
        let stringValue = '-';

        if (value) {
            stringValue = i18n.t(`createdMethods.${value}`);
        }

        return `<span data-export="${value}">${stringValue}</span>`;
    },

    formatCreatedBy(lead) {
        const name = getFullName(lead.creator);

        return `
            <span data-export="${name}">
                ${name}
            </span>
        `;
    },

    formatUpdatedBy(lead) {
        const name = getFullName(lead.updater);

        return `
            <span data-export="${name}">
                ${name}
            </span>
        `;
    },

    formatLeadState(lead) {
        let leadState = 'none';
        const isMerged = !isEmpty(lead.merged_leads);
        const isAssociated = lead.customer?.leads?.length > 1 || !isEmpty(lead.associated_leads);

        if (isMerged && !isAssociated) {
            leadState = 'merged';
        }

        if (!isMerged && isAssociated) {
            leadState = 'associated';
        }

        if (isMerged && isAssociated) {
            leadState = 'bothStates';
        }

        if (lead.import_validated === false) {
            leadState = 'toCheck';
        }

        return `<span data-export="${leadState}">${i18n.t(`dashboards.leadState.${leadState}`)}</span>`;
    },

    formatImportFile(lead) {
        const file = lead.import && lead.import.original_file ? lead.import.original_file : '-';

        return `<span>${file}</span>`;
    },

    formatAllCustomField(lead, customField, groupDisplay = false) {
        if ([ClientCardSection.VEHICLE_WANTED, ClientCardSection.VEHICLE_EXCHANGE].includes(customField.section)) {
            this.formatVehiclesCustomFields(lead, customField);
        }

        return this.formatCustomField(lead, customField, groupDisplay);
    },

    formatVehiclesCustomFields(lead, customField) {
        const customFieldObjects = lead.getVehicleCustomFieldsById(customField.id, customField.section);
        const values = [];

        customFieldObjects.forEach(vehicleObject => {
            const customable = vehicleObject.custom_fields.find(customFieldObject => customFieldObject.id == customField.id);

            let value = customable?.pivot?.value[0] || '-';
            value = this.formatCustomFieldValue(value, customField, vehicleObject);

            values.push(value);
        });

        return values.join('<br>');
    },

    formatCustomField(lead, customField, groupDisplay = false) {
        const leadCustomField = lead.custom_fields.find(custom => custom.id == customField.id);
        const tooltip = groupDisplay ? `data-tooltip="${customField.name}"` : '';
        let value = leadCustomField && leadCustomField.pivot.value.length ? leadCustomField.pivot.value.join(', ') : '-';
        const valueExport = value;

        value = this.formatCustomFieldValue(value, customField, leadCustomField);

        return `<span data-export="${valueExport}" ${tooltip}>${value}</span>`;
    },

    formatCustomFieldValue(value, customField, modelCustomField) {
        if (customField.type == 'datetime' && modelCustomField) {
            if (value && !as_locale(value).isValid()) {
                value = i18n.t('date.invalidDate');
            } else {
                const date = new ActivixDate(value, 'dateTime');
                value = date.toHumanShort();
            }
        } else if (customField.type == 'currency' && modelCustomField) {
            value = toMoney(value ? formatCurrency.toDecimal(value) : value, 2);
        }

        return value;
    },

    formatAverageSpending(lead) {
        const averageSpending = (lead.customer && lead.customer.average_spending) || lead.product_total || 0;

        return `<span data-export="${averageSpending ? 'yes' : 'no'}">${toMoney(averageSpending)}</span>`;
    },

    formatAddress(lead) {
        let address = '-';

        if (!empty(lead.address)) {
            address = lead.address[0];

            if (lead.address[1]) {
                address += '<br>';
                address += lead.address[1];
            }
        }

        return `<span class="break-all" data-export="${address.replace(/<br>/g, '\n')}">${address}</span>`;
    },

    formatLastPresentedDate(lead) {
        const presentedDate = new ActivixDate(lead.presented_date);
        const lastPresentedDate = new ActivixDate(get(lead.customer, 'last_presented_date', null));
        const date = lastPresentedDate.toHumanShort(false) || presentedDate.toHumanShort(false);

        return `<span data-export="${date || i18n.t('date.invalidDate')}">${date || '-'}</span>`;
    },

    formatOdometerLastVisit(lead) {
        const odometerLastVisit = lead.odometer_last_visit ? `${lead.odometer_last_visit} km` : '-';

        return `<span data-export="${odometerLastVisit}">${odometerLastVisit}</span>`;
    },

    formatServiceIntervalKm(lead) {
        const serviceIntervalKm = lead.service_interval_km ? `${lead.service_interval_km} km` : '-';

        return `<span data-export="${serviceIntervalKm}">${serviceIntervalKm}</span>`;
    },

    formatServiceMonthlyKm(lead) {
        const serviceMonthlyKm = lead.service_monthly_km ? `${lead.service_monthly_km} km` : '-';

        return `<span data-export="${serviceMonthlyKm}">${serviceMonthlyKm}</span>`;
    },

    formatNextPresentedDate(lead) {
        const nextPresentedDate = new ActivixDate(lead.next_presented_date).toHumanShort(false);

        return `
            <span data-export="${nextPresentedDate || i18n.t('date.invalidDate')}">
                ${nextPresentedDate || '-'}
            </span>
        `;
    },

    formatCode(lead) {
        const code = lead.code || '-';

        return `<span class="break-all" data-export="${code}">${code}</span>`;
    },

    formatStorage(lead) {
        const storage = lead.storage || '-';

        return `<span data-export="${storage}">${storage}</span>`;
    },

    formatMaintenance(lead) {
        const hasMaintenanceProduct = lead.products.some(product => {
            return product.type == 'service' && product.category == 'maintenance' && product.pivot.sold;
        });

        return hasMaintenanceProduct ? 'yes' : 'no';
    },

    formatOverSale(lead) {
        const intentions = lead.service_process.intention.value || [];
        const hasOverSale = lead.products.some(product => {
            return product.type == 'service' && product.pivot.sold && !intentions.includes(product.category);
        });

        return !!intentions.length && hasOverSale ? 'yes' : 'no';
    },

    formatTotalPrice(lead, asNumber, type) {
        const total = lead.products.reduce((total, product) => {
            if (product.type == type && product.pivot.sold) {
                const parsedPrice = Number(product.pivot.price);

                return Number.isNaN(parsedPrice) ? total : total + parsedPrice;
            }

            return total;
        }, 0);

        if (asNumber) {
            return total ? round(total, 2) : 0;
        }

        return `
            <span data-export="${total ? 'yes' : 'no'}">
                ${total ? toMoney(round(total, 2)) : '-'}
            </span>
        `;
    },

    formatTotalMinutes(lead, asNumber = false) {
        const total = lead.products.reduce((total, product) => {
            if (product.type == 'service' && product.pivot.sold) {
                const parsedMinutes = Number(product.pivot.minutes);

                return Number.isNaN(parsedMinutes) ? total : total + parsedMinutes;
            }
            return total;
        }, 0);

        if (asNumber) {
            return total ? round(total, 2) : 0;
        }

        return `
            <span data-export="${total ? 'yes' : 'no'}">
                ${total ? timeHms(round(total, 2) * 60, true) : '-'}
            </span>
        `;
    },

    formatServiceProduct(lead, productName) {
        const product = lead.products.find(product => product.type == 'service' && product.name == productName);
        return !isEmpty(product) && product.pivot.sold === true ? 'yes' : 'no';
    },

    formatInvoiced(lead) {
        const invoiced = lead.invoiced ? 'yes' : 'no';

        return `
            <span data-export="${invoiced}">
                ${i18n.t(`general.${invoiced}`)}
            </span>
        `;
    },

    formatWorkOrder(lead) {
        const workOrder = lead.work_order ? lead.work_order : '-';
        return `<span data-export="${workOrder}">${workOrder}</span>`;
    },

    formatRepairOrder(lead) {
        const repairOrder = lead.repair_order ? lead.repair_order : '-';
        return `<span data-export="${repairOrder}">${repairOrder}</span>`;
    },

    formatSector(lead) {
        const sector = lead.sector ? lead.sector : '-';
        return `<span data-export="${sector}">${sector}</span>`;
    },

    formatBudget(lead) {
        const budget = lead.budget ? lead.budget : '-';
        return `<span data-export="${budget}">${budget}</span>`;
    },

    formatBirthDate(lead) {
        return new ActivixDate(lead.birth_date, 'date').toHumanShort() || '-';
    },

    formatLeadUpdatedBySupplier(lead) {
        const string = [];

        (lead.updated_by_supplier || []).forEach(supplier => {
            string.push(`${i18n.t(`suppliers.${supplier.display_name || supplier}`)}`);
        });

        return string.join('<br>') || '-';
    },
};
