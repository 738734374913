import Enum from './Enum.js';

export default class LeadVehicleCondition extends Enum {
    static get entries() {
        return {
            average: 'average',
            clean: 'clean',
            extra_clean: 'extra_clean',
            rough: 'rough',
        };
    }

    static get AVERAGE() {
        return this.entries.average;
    }

    static get CLEAN() {
        return this.entries.clean;
    }

    static get EXTRA_CLEAN() {
        return this.entries.extra_clean;
    }

    static get ROUGH() {
        return this.entries.rough;
    }

    static get translationGroup() {
        return 'leadVehicleConditions';
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
