import Enum from './Enum.js';

export default class LeadVehicleFuel extends Enum {
    static get entries() {
        return {
            diesel: 'diesel',
            electric: 'electric',
            gasoline: 'gasoline',
            hybrid: 'hybrid',
        };
    }

    static get DIESEL() {
        return this.entries.diesel;
    }

    static get ELECTRIC() {
        return this.entries.electric;
    }

    static get GASOLINE() {
        return this.entries.gasoline;
    }

    static get HYBRID() {
        return this.entries.hybrid;
    }

    static get translationGroup() {
        return 'leadVehicleFuels';
    }

    static exist(property) {
        return this.hasOwnProperty(property);
    }
}
