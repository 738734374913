export default class VehicleVinMatchType {
    static get entries() {
        return {
            auto: 'auto',
            manual: 'manual',
            partial: 'partial',
            unique: 'unique',
            no_match: 'no_match',
        };
    }

    static get AUTO() {
        return this.entries.auto;
    }

    static get MANUAL() {
        return this.entries.manual;
    }

    static get PARTIAL() {
        return this.entries.partial;
    }

    static get UNIQUE() {
        return this.entries.unique;
    }

    static get NO_MATCH() {
        return this.entries.no_match;
    }
}
